/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of tile content scale.
 */

export const TileContentScale = {
  MEDIUM: "MEDIUM",
  SMALL: "SMALL",
};
