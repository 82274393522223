/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of tile thumbnail classname.
 */

export const TileThumbnailClassname = {
  MEDIUM: "tile__thumbnail___m",
};
