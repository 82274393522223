/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of tile content theme.
 */

export const TileContentTheme = {
  OFF_WHITE: "OFF_WHITE",
};
