/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of tile classname.
 */

export const TileClassname = {
  HORIZONTAL: "tile__row",
  VERTICAL: "tile__column",
};
