/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of button theme.
 */

export const ButtonTheme = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  WHITE: "WHITE",
};
