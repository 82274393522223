// extracted by mini-css-extract-plugin
export var f__aC = "footer-module--f__a--c--Klh86";
export var f__f = "footer-module--f__f--D+-Yy";
export var f__s = "footer-module--f__s--KWgCB";
export var footer = "footer-module--footer--A9iq7";
export var footer__hero = "footer-module--footer__hero--nCdkI footer-module--f__s--KWgCB footer-module--f__a--c--Klh86";
export var footer__hero__logo = "footer-module--footer__hero__logo--XKBrG footer-module--f__f--D+-Yy";
export var footer__hero__socials = "footer-module--footer__hero__socials--peEzA footer-module--f__a--c--Klh86";
export var footer__legal = "footer-module--footer__legal--J5PtT footer-module--f__s--KWgCB footer-module--f__a--c--Klh86 footer-module--f__f--D+-Yy";
export var footer__legal__copy = "footer-module--footer__legal__copy--P9JPg";
export var footer__nav = "footer-module--footer__nav--J2G8A footer-module--f__s--KWgCB";
export var footer__nav__item = "footer-module--footer__nav__item--7QESP footer-module--f__f--D+-Yy";
export var footer__nav__link = "footer-module--footer__nav__link--XRHRq";