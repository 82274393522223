/**
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * Possible values of anchor target attribute.
 */

export const Target = {
  BLANK: "_blank",
  SELF: "_self",
};
